<template>
  <div class="text-left">
    <div class="flex justify-center">
      <div class="w-1/2 text-center" v-if="donnes.ordinanceUrls !== null">
        <upload
          :icon="icons.ordonnance"
          background="#FBFBFB"
          border="1px dashed #C4C4C4"
          radius="5px"
          height="68px"
          label="Ordonnance"
          color="#606060"
            @oga="ordonance"
        />
      </div>

      <div class="w-1/2 ml-6" v-if="donnes.documentUrls !== null">
        <upload
          :icon="icons.document"
          background="#FBFBFB"
          height="68px"
          border="1px dashed #C4C4C4"
          radius="5px"
          label="Document"
          color="#606060"
            @oga="document"
        />
      </div>

      <div class="w-full text-center text-60 text-c14" v-if="donnes.documentUrls === null && donnes.ordinanceUrls === null"> Aucun document ajouter à cette commande </div>

    </div>
  </div>
</template>

<script>
import document from '../../../assets/icons/doc.svg'
import ordonnance from '../../../assets/icons/file.svg'
import upload from './showDocument'

export default {
  name: "Index",

  components: {
    upload,
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        document,
        ordonnance
      },
    }
  },

  created () {
  },

  methods: {
    ordonance(){
      window.open(this.donnes.ordinanceUrls[0], "_blank");
    },
    document(){
      window.open(this.donnes.documentUrls[0], "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.inputo {
  background: #FFFFFF;
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
}

</style>
